import React from "react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, TextField, Typography } from "@mui/material";

import styles from "./styles";
import FormModal from "../../../../../components/Dashboard/formModal/FormModal";
import { closeAuditorModal, isAuditorModalOpen } from "../../../../../redux/slices/modal/ModalSlice";
import { addAuditor } from "../../../../../redux/slices/codeAuditors/codeAuditorsSlice";

const AuditorModal = () => {
  const validationSchema = z.object({
    name: z.string().trim().min(1, { message: "Name is required" }),
    email: z.string().trim().email({ message: "Invalid email format" }).refine((email) => {
      const domain = email.split('@')[1];
      return domain === 'purelogics.com' || domain === 'purelogics.net';
    }, { message: "Email must be from purelogics.com or purelogics.net" }),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      email: "",
    }
  });

  const dispatch = useDispatch();
  const isOpen = useSelector(isAuditorModalOpen);

  const handleClose = () => {
    reset();
    dispatch(closeAuditorModal());
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    dispatch(addAuditor(data));
    reset();
  };

  return (
    <FormModal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Add Code Auditor"
      styles={styles}
      isIcon={true}
    >
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <Box pt={5}>
          {/* Auditor Name */}
          <Box sx={styles.modalFieldBox}>
            <Typography component="span" variant="body1">
              Auditor Name
            </Typography>
            <TextField
              placeholder="Add auditor name here"
              id="name"
              type="text"
              {...register("name")}
              helperText={errors.name?.message}
              error={Boolean(errors.name)}
              sx={styles.modalTextField}
            />
          </Box>
          {/* Auditor Email */}
          <Box sx={styles.modalFieldBox} pt={2}>
            <Typography component="span" variant="body1">
              Auditor Email
            </Typography>
            <TextField
              placeholder="Add auditor email here"
              id="email"
              type="email"
              {...register("email")}
              helperText={errors.email?.message}
              error={Boolean(errors.email)}
              sx={styles.modalTextField}
            />
          </Box>
          {/* Submit button */}
          <Box pt={4} sx={styles.modalFlexBox}>
            <Button
              variant="contained"
              type="submit"
              sx={styles.modalBtn}
            >
              <AddIcon />
              Add Auditor
            </Button>
          </Box>
        </Box>
      </form>
    </FormModal>
  );
};

export default AuditorModal;
