const styles = {
  typPh: (theme) => ({
    fontSize: "12px",
    color: "#878A99"
  }),
  mltMenu: (theme) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: 0.5,
  }),
  chkBox: (theme) => ({
    "& .MuiSvgIcon-root": {
      fontSize: "medium",
    }
  })
}

export default styles;

// display: flex;
// justify-content: space-between;
// align-items: center;
// padding-top: 31px;