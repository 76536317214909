import React from "react";

import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';


const BoilerRating = ({ value }) => {
  return (
    <Rating
      readOnly
      name="hover-feedback"
      value={value ?? 0}
      precision={0.5}
      emptyIcon={
        <StarIcon
          style={{ opacity: 0.55 }}
          fontSize="inherit"
        />
      }
    />
  )
}

export default BoilerRating;