import React, { useEffect, useState } from "react";

import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MuiChipsInput } from 'mui-chips-input'
import { MuiFileInput } from "mui-file-input";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import CloseIcon from '@mui/icons-material/Close'
import FolderZipIcon from '@mui/icons-material/FolderZip';
import AddIcon from '@mui/icons-material/Add';
import StarIcon from '@mui/icons-material/Star';
import { Box, Button, FormHelperText, Paper, Rating, Stack, TextField, Typography } from "@mui/material";

import styles from "./styles";
import MultiSelect from "../multiSelect";
import ChipList from "../chipList/ChipList";
import { labels } from "../../../db/raiting";
import { getAllCategories } from "../../../redux/slices/categories/categoriesSlice";
import { getAllTechStacks } from "../../../redux/slices/techStacks/techStacksSlice";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import { openAuditorModal, openDeveloperModal } from "../../../redux/slices/modal/ModalSlice";
import { getDevelopers, removeDeveloper } from "../../../redux/slices/developers/devlopersSlice";
import { getAuditors, removeAuditor } from "../../../redux/slices/codeAuditors/codeAuditorsSlice";
import { getAllCodeAuditStatus } from "../../../redux/slices/codeAuditStatus/codeAuditStatusSlice";
import { createBoilerplateAPI, updateBoilerplateAPI } from "../../../redux/slices/boilerplate/boilerplateThunk";
import { formatTechStackOptions, getCategoryIdAndName, getCodeAuditStatusIdAndName, getSelectedId, getSelectedIds, getTechStackIdAndName, renameAuditorFields, renameDeveloperFields } from "../../../utils/sliceFilter";


const BoilerPlateForm = ({ boilerDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
const validationSchema = z.object({
  name: z.string().trim().min(1, { message: "Name is required" }),
  description: z.string().trim().min(1, { message: "Description is required" }),
  categories: z.array(z.string()).nonempty({ message: "At least one category is required" }),
  codeAuditReportLink: z.string().url("Invalid URL format").trim(),
  techStacks: z.array(z.string()).nonempty({ message: "At least one tech stack is required" }),
  keywords: z.array(z.string()).nonempty({ message: "At least one keyword is required" }),
  bitbucketUrl: z.string().url("Invalid URL format").trim(),
  codeAuditStatus: z.string().trim().min(1, { message: "Code audit status is required" }),
  rating: z.number().optional(), 
  comments: z.string().trim().min(1, { message: "Comments are required" }),
  sampleWorkUrl: z.string().url("Invalid URL format").trim(),
  file: z.any(),
  isActive: z.any()
}).refine(data => {
  if (data.codeAuditStatus === 'DONE') {
    return data.rating != null; // rating should be present when codeAuditStatus is 'DONE'
  }
  return true;
}, {
  message: "Rating is required when code audit status is DONE",
  path: ["rating"], 
});

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      keywords: [],
      categories: [],
      techStacks: [],
      rating: 0,
      file: undefined
    }
  });

  const [categories, setCategories] = useState([]);
  const [techStacks, setTechStacks] = useState([]);
  const [codeAuditStatus, setCodeAuditStatus] = useState('');
  const [isActive, setIsActive] = useState();

  const [activeStatus] = useState([
    { id: 1, name: "Not Active" },
    { id: 2, name: "Active" }
  ]);


  useEffect(() => {
    if (boilerDetail) {
      reset({
        name: boilerDetail.boilerplate_name || '',
        description: boilerDetail.description || '',
        categories: boilerDetail.category || [],
        codeAuditReportLink: boilerDetail.code_audit_report || '',
        techStacks: boilerDetail.tech || [],
        keywords: boilerDetail.keywords || [],
        bitbucketUrl: boilerDetail.bitbucket_url || '',
        codeAuditStatus: boilerDetail.code_audit_status || '',
        rating: boilerDetail.code_quality || 0,
        comments: boilerDetail.comments || '',
        sampleWorkUrl: boilerDetail.sample_work_url || '',
        file: boilerDetail.code_zip_file || ''
      });

      setCategories(boilerDetail.category || []);
      setTechStacks(boilerDetail.tech || []);
      setCodeAuditStatus(boilerDetail.code_audit_status || '');
      setIsActive(boilerDetail?.is_active ? "Active" : "Not Active")
    }
  }, [boilerDetail, dispatch, reset]);


  const { categories: _categories } = useSelector(getAllCategories);
  const { techStacks: _techStacks } = useSelector(getAllTechStacks);
  const { codeAuditStatus: _codeAuditStatus } = useSelector(getAllCodeAuditStatus);
  const companyId = useSelector(getUserCompanyId);
  const { userToken } = useSelector(getUserAuth);
  const auditors = useSelector(getAuditors);
  const developers = useSelector(getDevelopers);

  const handleOpenAuditorModal = () => {
    dispatch(openAuditorModal());
  };

  const handleOpenDeveloperModal = () => {
    dispatch(openDeveloperModal());
  };

  const handleAuditorDelete = (index) => {
    dispatch(removeAuditor(index));
  }

  const handleDeveloperDelete = (index) => {
    dispatch(removeDeveloper(index));
  }

  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(-1);
  
  const getLabelText = (value) => {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  };

const onSubmit = (data, e) => {
  e.preventDefault();

  // Check if code audit status is not "DONE", then set rating to 0
  if (data.codeAuditStatus !== 'DONE') {
    data.rating = 0;
  }

  const categoryIds = getSelectedIds(data.categories, getCategoryIdAndName(_categories));
  const techStackIds = getSelectedIds(data.techStacks, getTechStackIdAndName(_techStacks));
  const codeAuditStatusId = getSelectedId(data.codeAuditStatus, getCodeAuditStatusIdAndName(_codeAuditStatus));

  const payload = {
    ...data,
    id: boilerDetail?.id,
    userToken,
    categories: categoryIds,
    techStacks: techStackIds,
    codeAuditStatus: codeAuditStatusId,
    companyId,
    auditors: renameAuditorFields(auditors),
    developers: renameDeveloperFields(developers),
    zipFileUrl: boilerDetail?.code_zip_file,
    navigate,
  };

  // Dispatch action for creating or updating the boilerplate
  if (boilerDetail) {
    dispatch(updateBoilerplateAPI(payload));
  } else {
    dispatch(createBoilerplateAPI(payload));
  }
};



  return (
    <>
      <Paper
        elevation={0}
        square={true}
        component="section"
        sx={styles.abpPaper}
      >
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack
            direction="row"
            gap={4}
            sx={styles.abpStack}
          >
            {/* Leftside */}
            <Box flex={6} >

              {/* Boilerplate Name */}
              <Box sx={styles.abpdf}>
                <Typography component="span" variant="body1">
                  Name
                </Typography>
                <TextField
                  placeholder="Add boiler palate name here"
                  id="name"
                  type="text"
                  {...register("name")}
                  helperText={errors.name?.message}
                  error={Boolean(errors.name)}
                  sx={styles.abpTextField}
                />
              </Box>

              {/* Boilerplate Description */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Description
                </Typography>
                <TextField
                  placeholder="Add boiler palate description here"
                  multiline
                  rows={4}
                  id="description"
                  {...register("description")}
                  helperText={errors.description?.message}
                  error={Boolean(errors.description)}
                  sx={styles.abpTextField}
                />
              </Box>

              {/* Select Boilerplate Category */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Category
                </Typography>
                <MultiSelect
                  placeholder={"Select Category(s)"}
                  id={"category"}
                  selectedData={categories}
                  setSelectedData={setCategories}
                  apiData={getCategoryIdAndName(_categories)}
                  register={register("categories")}
                  error={errors.categories}
                  multiline={true}
                  style={{ width: "289px" }}
                />
              </Box>

              {/* Boilerplate Code Audit Report Url */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Code Audit Report
                </Typography>
                <TextField
                  placeholder="File URL here"
                  id="code-audit-report-link"
                  type="url"
                  {...register("codeAuditReportLink")}
                  helperText={errors.codeAuditReportLink?.message}
                  error={Boolean(errors.codeAuditReportLink)}
                  sx={styles.abpTextField}
                />
              </Box>

              {/* Boilerplate Code Audited by */}
              <Box sx={styles.abpdfgCAB} pt={2}>
                <Typography component="span" variant="body1">
                  Code Audited by
                </Typography>
                <Box>
                  <Button
                    variant='contained'
                    sx={styles.responsiveButton}
                    onClick={() => handleOpenAuditorModal()}
                  >
                    <AddIcon />
                    Auditor
                  </Button>
                </Box>
              </Box>
              {/* Code Audited by Names render here */}
              <Box sx={styles.chipList}>
                <Box></Box>
                <ChipList
                  items={auditors}
                  handleDelete={handleAuditorDelete}
                />
              </Box>
            </Box>

            {/* Rightside */}
            <Box flex={6} >
              <Box sx={styles.abpdf}>

                {/* Select Tech Stacks */}
                <Typography component="span" variant="body1">
                  Techstack(s)
                </Typography>
                <MultiSelect
                  placeholder={"Select Techstack(s)"}
                  id={"techstack"}
                  selectedData={techStacks}
                  setSelectedData={setTechStacks}
                  apiData={formatTechStackOptions(_techStacks)}
                  register={register("techStacks")}
                  error={errors.techStacks}
                  multiline={true}
                  style={{ width: "289px" }}
                />
              </Box>

              {/* Add Keywords */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  List of Keywords
                </Typography>
                <Controller
                  name="keywords"
                  control={control}
                  render={({ field }) => (
                    <MuiChipsInput
                      {...field}
                      helperText={errors.keywords?.message}
                      error={Boolean(errors.keywords)}
                      sx={styles.abpTextField}
                    />
                  )}
                />
              </Box>

              {/* Add Developers */}
              <Box sx={styles.abpdfgDEVs} pt={2}>
                <Typography component="span" variant="body1">
                  Developer(s)
                </Typography>
                <Box>
                  <Button
                    variant='contained'
                    sx={styles.abpBtn}
                    aria-label="Add Developer"
                    onClick={() => handleOpenDeveloperModal()}
                  >
                    <AddIcon />
                    Developer
                  </Button>
                </Box>
              </Box>
              {/* Code Developers Names render here */}
              <Box sx={styles.chipList}>
                <Box></Box>
                <ChipList
                  items={developers}
                  handleDelete={handleDeveloperDelete}
                />
              </Box>

              {/* Add Bitbucket URL */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Bitbucket URL
                </Typography>
                <TextField
                  placeholder="Add relevent keywords"
                  id="bitbucket-url"
                  type="url"
                  {...register("bitbucketUrl")}
                  helperText={errors.bitbucketUrl?.message}
                  error={Boolean(errors.bitbucketUrl)}
                  sx={styles.abpTextField}
                />
              </Box>

              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Code Audit Status
                </Typography>
                <Controller
                  name="codeAuditStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <MultiSelect
                      placeholder="Select code audit status"
                      id="audit-status"
                      selectedData={value} 
                      setSelectedData={(selectedValue) => {
                        onChange(selectedValue);
                        setCodeAuditStatus(selectedValue);
                      }}
                      apiData={getCodeAuditStatusIdAndName(_codeAuditStatus)}
                      error={errors.codeAuditStatus}
                      multiline={false}
                      style={{ width: "289px" }}
                    />
                  )}
                />
              </Box>
            {codeAuditStatus === 'DONE' && (
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Code Quality Rating
                </Typography>
                <Controller
                  name="rating"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Box>
                      <FormHelperText error>
                        {errors.rating?.message}
                      </FormHelperText>
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={1}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          onChange(newValue !== null ? newValue : null);
                          setRating(newValue !== null ? newValue : null);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                          />
                        }
                      />
                    </Box>
                  )}
                />
              </Box>
            )}

            </Box>
          </Stack>

          {/* Comments/Remarks */}
          <Box sx={styles.abpRmk}>
            <Box >
              <Typography component="span" variant="body1">
                Comments/Remarks
              </Typography>
            </Box>
            <TextField
              placeholder="Add boilerpalate comments here"
              multiline
              rows={4}
              fullWidth
              id="comments"
              type="url"
              {...register("comments")}
              helperText={errors.comments?.message}
              error={Boolean(errors.comments)}
            />
          </Box>

          {/* Bottom stack  */}
          <Stack
            direction="row"
            gap={4}
            sx={styles.bottomStack}
          >
            {/* Leftside */}
            <Box flex={6}>
              {/* Sample Work URL */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Sample Work URL
                </Typography>
                <TextField
                  placeholder="www.workurlhere.com/demo.php"
                  id="sample-work-url"
                  type="url"
                  {...register("sampleWorkUrl")}
                  helperText={errors.sampleWorkUrl?.message}
                  error={Boolean(errors.sampleWorkUrl)}
                  sx={styles.abpTextField}
                />
              </Box>

              {/* Upload File */}
              <Box sx={styles.abpdf} pt={2}>
                <Typography component="span" variant="body1">
                  Upload File (.zip)
                </Typography>
                <Controller
                  name="file"
                  control={control}
                  render={({ field }) => (
                    <MuiFileInput
                      {...field}
                      helperText={errors?.file?.message}
                      error={Boolean(errors.file)}
                      sx={styles.abpTextField}
                      clearIconButtonProps={{
                        title: "Remove",
                        children: <CloseIcon fontSize="small" />
                      }}
                      InputProps={{
                        startAdornment: <FolderZipIcon fontSize="medium" />,
                        inputProps: {
                          accept: '.zip',
                        },
                      }}
                    />
                  )}
                />

              </Box>
            </Box>

            {/* Right Side */}
            <Box flex={6}>
              {/* Select Status */}
              {boilerDetail && (
                <>
                  <Box sx={styles.abpdf} pt={2}>
                    <Typography component="span" variant="body1">
                      Active Status
                    </Typography>
                    <MultiSelect
                      placeholder={"Active status"}
                      id={"isActive"}
                      selectedData={isActive}
                      setSelectedData={setIsActive}
                      apiData={activeStatus}
                      register={register("isActive")}
                      error={errors.isActive}
                      multiline={false}
                      style={{ width: "289px" }}
                    />
                  </Box>

                  {/* Already Uploaded File Name*/}
                  <Box sx={styles.abpdf} pt={2}>
                    <Typography component="span" variant="body1">
                      Uploaded File
                    </Typography>
                    <TextField
                      value={boilerDetail?.code_zip_file}
                      id="uploaded-file-name"
                      type="text"
                      disabled
                      sx={styles.abpTextField}
                    />
                  </Box>
                </>
              )}
            </Box>
          </Stack>

          <Box pt={2}>
            <Button
              variant="contained"
              type={"submit"}
              sx={styles.responsiveButton}
            >
              {!boilerDetail ?
                "Add Boilerplate" :
                "Update Boilerplate"
              }
            </Button>
          </Box>
        </form>
      </Paper >
    </>
  )
}

export default BoilerPlateForm;