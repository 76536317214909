import { createSlice } from "@reduxjs/toolkit";
import { feedbackCreateAPI, getFeedbacksAPI, handlers } from "./feedbacksThunk";

const initialState = {
  isLoading: false,
  isSuccess: false,
  message: null,
  error: null,
  feedbacks: [],
};

export const feedbacks = createSlice({
  name: "feedbacks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      feedbackCreateAPI.pending,
      handlers.feedbackCreateAPI.pending
    );
    builder.addCase(
      feedbackCreateAPI.fulfilled,
      handlers.feedbackCreateAPI.success
    );
    builder.addCase(
      feedbackCreateAPI.rejected,
      handlers.feedbackCreateAPI.rejected
    );

    builder.addCase(getFeedbacksAPI.pending, handlers.getFeedbacksAPI.pending);
    builder.addCase(
      getFeedbacksAPI.fulfilled,
      handlers.getFeedbacksAPI.success
    );
    builder.addCase(
      getFeedbacksAPI.rejected,
      handlers.getFeedbacksAPI.rejected
    );
  },
});

export const getAllFeedbacks = (state) => state.feedbacks;

export default feedbacks.reducer;
