import React, { useCallback, useEffect, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Badge,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import styles from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, userData } from "../../../../redux/slices/auth/authSlice";
import UserProfileMenu from "../../menu/UserProfileMenu";
import NotificationMenu from "../../menu/NotificationMenu";
import { listNotificationAPI } from "../../../../redux/slices/notification/notificationThunk";
import { getAllNotificaitons } from "../../../../redux/slices/notification/notificationSlice";
import { checkIsAdmin } from "../../../../utils/checkIsAdmin";

const Navbar = ({ setDrawOpen }) => {
  const naviagte = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(userData);
  const { userToken } = useSelector(getUserAuth);
  const { notificationCount } = useSelector(getAllNotificaitons);
  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);

  useEffect(() => {
    let payload = { userToken };
    checkAdmin && dispatch(listNotificationAPI(payload));
  }, [checkAdmin, dispatch, userToken]);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorNotify, setAnchorNotify] = useState(null);

  const open = Boolean(anchorEl);
  const openNotify = Boolean(anchorNotify);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifyClick = (event) => {
    let payload = { userToken };
    setAnchorNotify(event.currentTarget);
    dispatch(listNotificationAPI(payload));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotifyClose = () => {
    setAnchorNotify(null);
  };

  const handleDrawOpen = useCallback(() => {
    setDrawOpen(true);
  }, [setDrawOpen]);

  return (
    <AppBar position="static" color="inherit">
      <Toolbar sx={styles.nbToolbar}>
        <Box sx={styles.nbBox}>
          {/* Menu Navigation Button */}
          <Box ml={1}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => handleDrawOpen()}
              sx={styles.drawerOpener}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Add Boilerplate Button */}
          {checkAdmin && (
            <Box>
              <Button
                variant="contained"
                sx={styles.responsiveButton}
                onClick={() => naviagte("add-boilerplate")}
              >
                <AddIcon />
                Boilerplate
              </Button>
            </Box>
          )}

          {/* Search-bar section */}
          {/* <Box sx={styles.nbSb}>
            <TextField
              variant="outlined"
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box> */}
        </Box>

        <Box sx={styles.nbfc}>
          {checkAdmin && (
            <>
              {/* View Notification Button*/}
              <Box>
                <IconButton onClick={handleNotifyClick}>
                  <Badge badgeContent={notificationCount} color="error">
                    <NotificationsNoneOutlinedIcon fontSize="medium" />
                  </Badge>
                </IconButton>
                <NotificationMenu
                  anchorNotify={anchorNotify}
                  handleNotifyClose={handleNotifyClose}
                  openNotify={openNotify}
                />
              </Box>
            </>
          )}

          {/* User profile section */}
          <Box sx={styles.userProfile}>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              sx={{
                "&:focus": {
                  outline: "none",
                },
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <Avatar src={user?.picture ? user?.picture : ""} alt="User" />
              <Box ml={1} sx={styles.userText}>
                <Typography component="span" sx={styles.username}>
                  {user?.name}
                </Typography>
                <Typography component="span" sx={styles.userType}>
                  {user?.userType}
                </Typography>
              </Box>
            </IconButton>
            <UserProfileMenu
              anchorEl={anchorEl}
              handleClose={handleClose}
              open={open}
            />
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
