import { createSlice } from '@reduxjs/toolkit'
import { boilerplateDetailAPI, boilerplateListingAPI, createBoilerplateAPI, handlers, updateBoilerplateAPI } from './boilerplateThunk';

const initialState = {
  boilerplates: [],
  boilerplateDetail: [],
  isLoading: false,
  isSuccess: false,
  error: null,
  message: null,
  previousPageUrl: null,
  nextPageUrl: null,
  totalCount: 0,
};


export const boilerplateSlice = createSlice({
  name: 'boilerplates',
  initialState,
  reducers: {
    setBoilerSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBoilerplateAPI.pending, handlers.createBoilerplateAPI.pending);
    builder.addCase(createBoilerplateAPI.fulfilled, handlers.createBoilerplateAPI.success);
    builder.addCase(createBoilerplateAPI.rejected, handlers.createBoilerplateAPI.rejected);

    builder.addCase(boilerplateListingAPI.pending, handlers.boilerplateListingAPI.pending);
    builder.addCase(boilerplateListingAPI.fulfilled, handlers.boilerplateListingAPI.success);
    builder.addCase(boilerplateListingAPI.rejected, handlers.boilerplateListingAPI.rejected);

    builder.addCase(boilerplateDetailAPI.pending, handlers.boilerplateDetailAPI.pending);
    builder.addCase(boilerplateDetailAPI.fulfilled, handlers.boilerplateDetailAPI.success);
    builder.addCase(boilerplateDetailAPI.rejected, handlers.boilerplateDetailAPI.rejected);

    builder.addCase(updateBoilerplateAPI.pending, handlers.updateBoilerplateAPI.pending);
    builder.addCase(updateBoilerplateAPI.fulfilled, handlers.updateBoilerplateAPI.success);
    builder.addCase(updateBoilerplateAPI.rejected, handlers.updateBoilerplateAPI.rejected);
  }
})

export const { setBoilerSuccess } = boilerplateSlice.actions;

export const getAllBoilerplates = (state) => state.boilerplates;
export const getBoilerplateDetail = (state) => state.boilerplates.boilerplateDetail;
export const getTotalCount = (state) => state.boilerplates.totalCount;

export default boilerplateSlice.reducer;