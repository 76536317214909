import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { googleLoginAPI } from '../../redux/slices/auth/authThunk';
import { getUserAuth } from '../../redux/slices/auth/authSlice';

import styles from './styles';
import googleLogo from "../../assets/login/google_icon.svg";
import BackdropLoading from '../BackdropLoading';

const GoogleLogin = ({ text }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const { isLoading, isAdmin, error: apiError, userToken } = useSelector(getUserAuth);

  const [errors, setErrors] = useState(false);

  useEffect(() => {
  // Check if userToken exists; if not, redirect to login
  if (!userToken) {
    navigate('/login'); // Redirect to login if session has expired
  } else {
    // Existing navigation logic based on isAdmin
    if (isAdmin) {
      navigate('/dashboard');
    } else {
      navigate('/dashboard/boilerplate-listing/allstack');
    }
  }
}, [userToken, isAdmin, navigate]);



  const googleLoginBtn = useGoogleLogin({
    onSuccess: async response => {
      setErrors(false);
      try {
        const googleResponse = await axios
          .get("https://www.googleapis.com/oauth2/v3/userinfo", {
            headers: {
              "Authorization": `Bearer ${response.access_token}`
            }
          });
        setErrors(false);
        googleResponse.access_token = response.access_token;
        dispatch(googleLoginAPI(googleResponse));
      }
      catch (err) {
        setErrors(true);
      }
    }
  });

  return (
    <>
      <BackdropLoading
        isLoading={isLoading}
      />
      <Box>
        <Typography component="h5" sx={styles.glmt}>
          Login to your Account
        </Typography>
        <Box mt={4}>
          <Button
            variant="outlined"
            onClick={googleLoginBtn}
            sx={styles.googleButton}
          >
            <Box sx={styles.gLogo}>
              <img src={googleLogo} alt="google" />
            </Box>
            <Typography sx={styles.glp}>
              {text}
            </Typography>
          </Button>
          {errors || apiError ? (
            <div className="errors">
              <p>
                {apiError}
              </p>
            </div>
          ) : (
            null
          )}
        </Box>
      </Box>
    </>
  )
}

export default GoogleLogin;