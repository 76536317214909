const styles = {
  bvPaper: (theme) => ({
    maxWidth: "100%",
    width: "100%",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "40px",
      maxWidth: "1026px",
    },
  }),

  //  bvDownloadButton: (theme) => ({
  //     transition: "all 0.3s ease-in-out",
  //     backgroundColor: theme.palette.primary.main,
  //     color: theme.palette.common.white,
  //     borderRadius: "8px",
  //     textTransform: "none", // Keeps text in the default case
  //     boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)", // Slight shadow for depth
  //     "&:hover": {
  //       backgroundColor: theme.palette.primary.dark,
  //       transform: "scale(1.05)",
  //     },
  //     [theme.breakpoints.down("xs")]: {
  //       fontSize: "10px",
  //       padding: theme.spacing(0.5, 0.75),
  //     },
  //     [theme.breakpoints.down("sm")]: {
  //       fontSize: "12px",
  //       padding: theme.spacing(0.75, 1.25),
  //     },
  //     [theme.breakpoints.up("md")]: {
  //       fontSize: "14px",
  //       padding: theme.spacing(1, 2),
  //     },
  //     [theme.breakpoints.up("lg")]: {
  //       fontSize: "16px",
  //       padding: theme.spacing(1.5, 3),
  //     },
  //   }),
  bvStack: (theme) => ({
    flexDirection: "column",
    gap: "20px",
    alignItems: "flex-start", // Align content to the start
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      gap: "30px",
    },
  }),

  bvpdf: (theme) => ({
    display: "flex",
    flexDirection: "row", // Use row to keep label and content on the same line
    alignItems: "center", // Center-align items vertically
    marginBottom: "8px",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  }),

  bvBD: (theme) => ({
    maxWidth: "100%",
    maxHeight: "110px",
    overflowY: "auto",
    [theme.breakpoints.up("md")]: {
      maxWidth: "365px",
    },
  }),
  bvCB: (theme) => ({
    maxWidth: "365px",
  }),
  bvChip: (theme) => ({
    backgroundColor: "#e0e0e0",
    color: "#333",
    fontWeight: "medium",
    margin: "4px 0",
    padding: "4px 8px",
    fontSize: "0.875rem",
    whiteSpace: "normal", // Allow text wrapping
    wordBreak: "break-word", // Break long words if needed
    maxWidth: "15rem", // Limit width to make it responsive

    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem",
      padding: "3px 6px",
      margin: "2px 0",
      maxWidth: "10rem",
    },
  }),

  bvLabel: (theme) => ({
    fontWeight: "bold",
    color: "#333",
    marginRight: "8px",
    marginBottom: "0", 
    [theme.breakpoints.up("sm")]: {
      marginRight: "8px",
    },
  }),

  bvContent: (theme) => ({
    color: "#555",
    fontSize: "1rem",
    wordWrap: "break-word", 
  }),

  bvDescription: (theme) => ({
    lineHeight: 1.6,
    color: "#666",
    marginTop: "8px",
  }),
};

export default styles;
