const styles = {
  nbToolbar: (theme) => ({
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
    height: "8.8vh",
    padding: theme.spacing(1, "2vw"),
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, "1vw"),
    },
  }),

  responsiveButton: (theme) => ({
    transition: "all 0.3s ease-in-out",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: "0.5rem",
    textTransform: "none",
    boxShadow: "0 0.125rem 0.5rem rgba(0, 0, 0, 0.15)",

    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      transform: "scale(1.05)",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.625rem",
      padding: theme.spacing(0.5, 0.75), // Use theme spacing for flexibility
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.75rem", // 12px -> 0.75rem
      padding: theme.spacing(0.75, 1.25),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "0.875rem",
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem", // 16px -> 1rem
      padding: theme.spacing(1.5, 3),
    },
  }),

  nbBox: (theme) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  }),

  drawerOpener: (theme) => ({
    color: theme.palette.text.primary,
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  }),

  nbfc: (theme) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gap: theme.spacing(1),
    },
  }),

  userProfile: (theme) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    borderRadius: "50px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      cursor: "pointer",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.25),
    },
  }),

  userText: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      display: "none", // Hide text on extra small screens for cleaner look
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5), // Reduce margin on small screens
    },
  }),

  username: (theme) => ({
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "20px",
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px", // Smaller font for extra small screens
    },
  }),

  userType: (theme) => ({
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    color: theme.palette.text.secondary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px", // Adjust font size for extra small screens
    },
  }),

  notificationIcon: (theme) => ({
    color: theme.palette.text.primary,
    "& .MuiBadge-badge": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem", // Smaller icon size for very small screens
    },
  }),

  avatar: (theme) => ({
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5), // Smaller avatar for small screens
    },
  }),

  nbSb: (theme) => ({
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5), // Reduce margin on small screens
    },
  }),
};

export default styles;
