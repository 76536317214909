import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth , getUserCompanyId} from "../../../redux/slices/auth/authSlice";
import { categoryUpdateAPI, createCategoryAPI } from "../../../redux/slices/categories/categoriesThunk";
import MultiSelect from "../multiSelect";
import { useNavigate } from "react-router-dom";

const CategoryForm = ({ ctgDetail }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId)

  const [isActive, setIsActive] = useState();

  const [activeStatus] = useState([
    { id: 1, name: "Not Active" },
    { id: 2, name: "Active" }
  ]);

  const validationSchema = z.object({
    name: z.string().trim().min(1, { message: "Name is requried" }),
    description: z.any(),
    isActive: z.any(),
  })

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
    }
  });

  useEffect(() => {
    if (ctgDetail) {
      reset({
        name: ctgDetail.category_name || "",
        description: ctgDetail.description || "",
      });
      setIsActive(ctgDetail?.is_active ? "Active" : "Not Active");
    }

  }, [ctgDetail, dispatch, navigate, reset]);


  const onSubmit = (data, e) => {
    e.preventDefault();

    const payload = {
      ...data,
      id: ctgDetail?.id,
      userToken,
      companyId:company_id,
      navigate,
    };

  if (!payload.id) {
    console.error("Category ID is undefined. Cannot update category.");
  }


    ctgDetail ?
      dispatch(categoryUpdateAPI(payload)) :
      dispatch(createCategoryAPI(payload));
  };

  return (
    <>
      <Paper
        elevation={0}
        square={true}
        component="section"
        sx={styles.ctgPaper}
      >
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Category Name */}
          <Box sx={styles.ctgdf}>
            <Typography component="span" variant="body1">
              Name
            </Typography>
            <TextField
              placeholder="Add category name here"
              id="name"
              type="text"
              {...register("name")}
              helperText={errors.name?.message}
              error={Boolean(errors.name)}
              sx={styles.ctgTextField}
            />
          </Box>

          {/* Category Description */}
          <Box sx={styles.ctgdf} pt={2}>
            <Typography component="span" variant="body1">
              Description
            </Typography>
            <TextField
              placeholder="Add category description here"
              multiline
              rows={6}
              id="description"
              {...register("description")}
              helperText={errors.description?.message}
              error={Boolean(errors.description)}
              sx={styles.ctgTextField}
            />
          </Box>

          {/* Select Status */}
          {ctgDetail &&
            <Box sx={styles.ctgdf} pt={2}>
              <Typography component="span" variant="body1">
                Active Status
              </Typography>
              <MultiSelect
                placeholder={"Active status"}
                id={"isActive"}
                selectedData={isActive}
                setSelectedData={setIsActive}
                apiData={activeStatus}
                register={register("isActive")}
                error={errors.isActive}
                multiline={false}
                style={{ width: "380px" }}
              />
            </Box>
          }

          {/* Submit Button */}
          <Box pt={3}>
            <Button
              variant="contained"
              type={"submit"}
              sx={styles.ctgBtn}
            >
              {!ctgDetail ?
                "Add Category" :
                "Update Category"}
            </Button>
          </Box>
        </form>
      </Paper >
    </>
  )
}

export default CategoryForm;