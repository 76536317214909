import React, { useEffect } from 'react';
import BrowserTabTitle from '../../../components/BrowserTabTitle'
import { useParams } from 'react-router-dom';
import BackdropLoading from '../../../components/BackdropLoading';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorAlert } from '../../../components/Alerts/Alerts';
import { Container } from '@mui/material';
import PageTitle from '../../../components/Dashboard/includes/pagetitle/PageTitile';
import { getUserAuth, getUserCompanyId } from '../../../redux/slices/auth/authSlice';
import { getAllTechStacks } from "../../../redux/slices/techStacks/techStacksSlice";
import TechstackForm from '../../../components/Dashboard/techstack';
import { techStackDetailAPI } from '../../../redux/slices/techStacks/techStacksThunk';

const Techstack = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { userToken } = useSelector(getUserAuth);
    const company_id = useSelector(getUserCompanyId)
  const {
    isLoading,
    error,
    techStackDetail
  } = useSelector(getAllTechStacks);

  useEffect(() => {
    let payload = {};
    payload.userToken = userToken;
    payload.id = id;
    payload.companyId = company_id;
    id && dispatch(techStackDetailAPI(payload));
  }, [id, userToken, dispatch,company_id]);

  return (
    <>
      <BrowserTabTitle
        title={!id ?
          "Add Techstack" :
          "Update Techstack"
        }
      />
      <BackdropLoading isLoading={isLoading} />
      {error && <ErrorAlert message={error} />}
      <Container maxWidth="">
        <PageTitle
          title={!id ?
            "Add Techstack" :
            `Update Techstack`}
          goBackPath={"/dashboard/boilerplate-techstack"}
        />
        <TechstackForm techDetail={id && techStackDetail} />
      </Container>
    </>
  )
}

export default Techstack;
