import { Box, CircularProgress } from "@mui/material";
import React from "react";

const AmplifySpinner = () => {
  return (
    <Box>
      <CircularProgress
        size={15}
        thickness={6}
        sx={{ color: "black" }}
      />
    </Box>
  )
}

export default AmplifySpinner;