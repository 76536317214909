import { createSlice } from '@reduxjs/toolkit'
import { getCodeAuditStatusAPI, handlers } from './codeAuditStatusThunk';

const initialState = {
  isLoading: false,
  error: null,
  codeAuditStatus: [],
};


export const codeAuditStatus = createSlice({
  name: 'codeAuditStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCodeAuditStatusAPI.pending, handlers.getCodeAuditStatusAPI.pending);
    builder.addCase(getCodeAuditStatusAPI.fulfilled, handlers.getCodeAuditStatusAPI.success);
    builder.addCase(getCodeAuditStatusAPI.rejected, handlers.getCodeAuditStatusAPI.rejected);
  }
})

export const getAllCodeAuditStatus = (state) => state.codeAuditStatus;


export default codeAuditStatus.reducer;