const styles = {
  fdPaper: (theme) => ({
    maxWidth: "100%",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "15px 20px",
    marginTop: "15px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "1026px",
      padding: "20px 30px",
    },
  }),
  fdStack: (theme) => ({
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row", // Align in row on medium screens
      alignItems: "center",
      gap: "10px", // Ensure consistent gap
    },
  }),
  fdTf: (theme) => ({
    width: "100%",
    paddingTop: "15px",
    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 60px)",
      paddingTop: "22px",
    },
  }),
  fdRating: (theme) => ({
    display: "flex",
    justifyContent: "flex-end",
  }),
  fdSpinner: (theme) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0",
  }),
  fdSection: (theme) => ({
    marginTop: "20px",
    maxHeight: "400px",
    overflowY: "auto",
    padding: "0 10px",
  }),
  emptyText: (theme) => ({
    fontSize: "14px",
    fontStyle: "italic",
    color: "#878A99",
    textAlign: "center",
    paddingTop: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
    },
  }),
  userFd: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "5px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      gap: "8px",
    },
  }),
  userFdName: (theme) => ({
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#2c3e50",
    [theme.breakpoints.up("md")]: {
      fontSize: "16px",
      lineHeight: "22.5px",
    },
  }),
  fdButton: (theme) => ({
    width: "100%", // Full width on small screens
    [theme.breakpoints.up("md")]: {
      width: "48px", // Fixed width on medium and larger screens
    },
    height: "48px",
    bgcolor: "primary.main",
    "&:hover": {
      bgcolor: "primary.dark",
    },
    transition: "background-color 0.3s ease",
  }),
  userFdText: (theme) => ({
    fontWeight: 400,
    fontSize: "12px",
    color: "#4f4f4f",
    marginTop: "8px",
    [theme.breakpoints.up("md")]: {
      fontSize: "14px",
    },
  }),
};

export default styles;
