import React, { useState } from "react";
import styles from "./styles";
import {
  Box,
  Button,
  Chip,
  Link,
  Paper,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BoilerRating from "../boilerRating";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LaunchIcon from "@mui/icons-material/Launch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import { useDispatch, useSelector } from "react-redux";
import { openDRModal } from "../../../redux/slices/modal/ModalSlice";
import { setIsSuccess } from "../../../redux/slices/downloadReason/downloadReasonSlice";
import { checkIsAdmin } from "../../../utils/checkIsAdmin";
import { getUserAuth } from "../../../redux/slices/auth/authSlice";

const BoilerView = ({ boilerDetail }) => {
  const dispatch = useDispatch();

  const handleOpenDRModal = () => {
    dispatch(openDRModal());
    dispatch(setIsSuccess(false));
  };

  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);

  const [expanded, setExpanded] = useState(false);
  const maxDescriptionLength = 250;

  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Paper elevation={4} component="section" sx={styles.bvPaper}>
      <Stack direction="row" spacing={3} sx={styles.bvStack}>
        {/* Left side */}
        <Box flex={6} sx={styles.bvLeftSide}>
          {/* Boilerplate Name */}
          <Box sx={styles.bvpdf}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Name:
            </Typography>
            <Typography variant="h6" sx={styles.bvContent}>
              {boilerDetail?.boilerplate_name}
            </Typography>
          </Box>

          {/* Boilerplate Category */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Category:
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.category?.map((value, index) =>
                isMobile ? (
                  <Tooltip title={value} key={index}>
                    <Chip label={value} size="small" sx={styles.bvChip} />
                  </Tooltip>
                ) : (
                  <Chip
                    label={value}
                    size="small"
                    sx={styles.bvChip}
                    key={index}
                  />
                )
              )}
            </Box>
          </Box>

          {/* Code Quality */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Code Quality:
            </Typography>
            <Box>
              <BoilerRating value={boilerDetail?.code_quality} />
            </Box>
          </Box>

          {/* Sample Work URL */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Sample Work URL:
            </Typography>
            <Link
              href={boilerDetail?.sample_work_url}
              target="_blank"
              rel="noopener"
              underline="hover"
              sx={{ display: "flex", alignItems: "center" }}
            >
              <LaunchIcon
                sx={{ fontSize: "20px", color: "primary.main", mr: 1 }}
              />
              View Sample
            </Link>
          </Box>
          {/* Boilerplate Description with "Read More" */}
          <Box
            sx={{
              ...styles.bvpdf,
              pt: 1,
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              padding: "16px",
              marginTop: "8px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 1, fontWeight: "bold", ml: -2 }}>
              Description:
            </Typography>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setExpanded(!expanded)}
            >
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  lineHeight: 1.6,
                  color: "#4a4a4a",
                  display: "inline",
                }}
              >
                {expanded
                  ? boilerDetail?.description
                  : `${boilerDetail?.description?.slice(
                      0,
                      maxDescriptionLength
                    )}`}
                {boilerDetail?.description?.length > maxDescriptionLength &&
                  !expanded && <span>...</span>}
              </Typography>
              {boilerDetail?.description?.length > maxDescriptionLength && (
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    ml: 1,
                    color: "primary.main",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ mr: 0.5, color: "primary.main" }}
                  >
                    {expanded ? "Read Less" : "Read More"}
                  </Typography>
                  {expanded ? (
                    <ExpandLessIcon sx={{ fontSize: "20px" }} />
                  ) : (
                    <ExpandMoreIcon sx={{ fontSize: "20px" }} />
                  )}
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* Right side */}
        <Box flex={6} sx={styles.bvRightSide}>
          {/* Tech stack(s) */}
          <Box sx={styles.bvpdf}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Tech stack(s):
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.tech?.map((value, index) =>
                isMobile ? (
                  <Tooltip title={value} key={index}>
                    <Chip label={value} size="small" sx={styles.bvChip} />
                  </Tooltip>
                ) : (
                  <Chip
                    label={value}
                    size="small"
                    sx={styles.bvChip}
                    key={index}
                  />
                )
              )}
            </Box>
          </Box>

          {/* List of Keywords */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              List of Keywords:
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.keywords?.map((value, index) =>
                isMobile ? (
                  <Tooltip title={value} key={index}>
                    <Chip label={value} size="small" sx={styles.bvChip} />
                  </Tooltip>
                ) : (
                  <Chip
                    label={value}
                    size="small"
                    sx={styles.bvChip}
                    key={index}
                  />
                )
              )}
            </Box>
          </Box>

          {/* Developers */}
          <Box sx={styles.bvpdf} pt={1}>
            <Typography variant="h6" sx={styles.bvLabel}>
              Developer(s):
            </Typography>
            <Box sx={styles.bvTags}>
              {boilerDetail?.developers?.length > 0 ? (
                boilerDetail?.developers?.map((value, index) =>
                  isMobile ? (
                    <Tooltip title={value?.name} key={index}>
                      <Chip
                        label={value?.name}
                        size="small"
                        sx={styles.bvChip}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      label={value?.name}
                      size="small"
                      sx={styles.bvChip}
                      key={index}
                    />
                  )
                )
              ) : (
                <Typography variant="body1" sx={styles.bvContent}>
                  N/A
                </Typography>
              )}
            </Box>
          </Box>

          {/* Admin-only fields */}
          {checkAdmin && (
            <>
              {/* Code Audit Report */}
              {boilerDetail?.code_audit_report && (
                <Box sx={styles.bvpdf} pt={1}>
                  <Typography variant="h6" sx={styles.bvLabel}>
                    Code Audit Report:
                  </Typography>
                  <Link
                    href={boilerDetail?.code_audit_report}
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LaunchIcon
                      sx={{ fontSize: "20px", color: "primary.main", mr: 1 }}
                    />
                    View Report
                  </Link>
                </Box>
              )}

              {/* Code Audited by */}
              <Box sx={styles.bvpdf} pt={1}>
                <Typography variant="h6" sx={styles.bvLabel}>
                  Code Audited by:
                </Typography>
                <Box sx={styles.bvTags}>
                  {boilerDetail?.auditors?.length > 0 ? (
                    boilerDetail.auditors.map((value, index) => (
                      <Chip
                        label={value?.name}
                        size="small"
                        key={index}
                        sx={styles.bvChip}
                      />
                    ))
                  ) : (
                    <Typography variant="body1" sx={styles.bvContent}>
                      N/A
                    </Typography>
                  )}
                </Box>
              </Box>

              {/* Bitbucket URL */}
              {boilerDetail?.bitbucket_url && (
                <Box sx={styles.bvpdf} pt={1}>
                  <Typography variant="h6" sx={styles.bvLabel}>
                    Bitbucket URL:
                  </Typography>
                  <Link
                    href={boilerDetail?.bitbucket_url}
                    target="_blank"
                    rel="noopener"
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <LaunchIcon
                      sx={{ fontSize: "20px", color: "primary.main", mr: 1 }}
                    />
                    View Repository
                  </Link>
                </Box>
              )}

              {/* Code Audit Status */}
              {boilerDetail?.code_audit_status && (
                <Box sx={styles.bvpdf} pt={1}>
                  <Typography variant="h6" sx={styles.bvLabel}>
                    Code Audit Status:
                  </Typography>
                  <Chip
                    label={boilerDetail?.code_audit_status}
                    size="small"
                    sx={styles.bvStatusChip}
                  />
                </Box>
              )}
            </>
          )}

          {/* Download Button placed right after the description */}
          <Box sx={styles.bvDownloadButton} pt={2}>
            <Button
              variant="contained"
              onClick={() => handleOpenDRModal()}
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "10px",
                padding: "12px",
              }}
            >
              <FileDownloadIcon sx={{ mr: 1 }} /> Download Boilerplate
            </Button>
          </Box>
        </Box>
      </Stack>
    </Paper>
  );
};

export default BoilerView;
