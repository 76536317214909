// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Sen", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}

.d-flex {
  display: flex !important;
}

.vh100 {
  height: 100vh;
}

.border {
  border: 1px solid black;
}

.dashboard-body {
  background-color: #F0F0F0;
  min-height: 100vh;
  height: auto;
}

.errors {
  color: #d32f2f;
  margin-top: 15px;
}

.fdForm {
  display: contents;
}

@media (max-width: 600px) {
  .techImg {
    width: 280px;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,8BAA8B;EAC9B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: \"Sen\", sans-serif;\n  font-weight: 400;\n  font-size: 1rem;\n  line-height: 1.5;\n  letter-spacing: 0.00938em;\n}\n\n.d-flex {\n  display: flex !important;\n}\n\n.vh100 {\n  height: 100vh;\n}\n\n.border {\n  border: 1px solid black;\n}\n\n.dashboard-body {\n  background-color: #F0F0F0;\n  min-height: 100vh;\n  height: auto;\n}\n\n.errors {\n  color: #d32f2f;\n  margin-top: 15px;\n}\n\n.fdForm {\n  display: contents;\n}\n\n@media (max-width: 600px) {\n  .techImg {\n    width: 280px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
