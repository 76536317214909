const styles = {
  techPaper: (theme) => ({
    maxWidth: "600px",
    border: "1px solid #E9EBEC",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    padding: "30px 30px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "350px",
    },
  }),
  techdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px", // added consistent spacing
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "12px",
    },
  }),
  techFI: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "90px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),
  techTextField: (theme) => ({
    width: "380px",
    [theme.breakpoints.down("sm")]: {
      width: "280px",
    },
  }),
  techBtn: (theme) => ({
    padding: "12px 24px",
    backgroundColor: "#4caf50", // primary green for button
    color: "#fff",
    textTransform: "none", // keep the button text sentence case
    fontSize: "16px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#388e3c", // darker hover effect
    },
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  }),
};

export default styles;