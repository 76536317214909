import { styled } from "@mui/material/styles";
import { Box, Typography, Button } from "@mui/material";

// Define styled components using MUI's `styled`
export const Container = styled(Box)(({ theme }) => ({
  height: "86vh", // Use full viewport height
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#f5f5f5",
  textAlign: "center",
  overflow: "hidden",
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(6),
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2),
  },
}));


export const IconContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  maxWidth: 500,
  marginBottom: theme.spacing(4),
}));

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: "#343a40",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
}));

export const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  color: "#495057",
  maxWidth: "42rem", 
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.success.dark,
    transform: "scale(1.05)",
  },
}));

export const ImageWrapper = styled("img")(({ theme }) => ({
  width: "100%",
  maxWidth: 600,
  height: "auto",
  marginBottom: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  animation: "fadeIn 1s ease-in-out",
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
}));
