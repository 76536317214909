import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import styles from "./styles";

const MultiSelect = ({
  style,
  placeholder,
  id,
  setSelectedData,
  apiData = [],
  register,
  error,
  multiline,
  selectedData = multiline ? [] : "",
}) => {

 const handleChange = (event) => {
  const { target: { value } } = event;

  // Ensure value is always an array for multiline, handle single-select otherwise
  if (multiline) {
    setSelectedData(typeof value === "string" ? value.split(",") : value);
  } else {
    setSelectedData(value);
  }
};


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Memoized function to render selected values
const renderSelectedValue = React.useMemo(() => (selected) => {
  
  // Add extra check for null values
  if (!selected || selected.length === 0 || selected[0] === null) {
    return <Typography sx={styles.typPh}>{placeholder}</Typography>;
  }

  return multiline ? (
    <Box sx={styles.mltMenu}>
      {selected.map((value) => (
        <Chip key={value} label={value} />
      ))}
    </Box>
  ) : (
    <Typography>{selected}</Typography>
  );
}, [multiline, placeholder]);


  return (
    <FormControl error={Boolean(error)} aria-labelledby={`${id}-label`}>
      <Select
        label="Search boilerplate"
        id={id}
        aria-label="multiline select"
        multiple={multiline}
        displayEmpty
        value={selectedData}
        {...register}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => renderSelectedValue(selected)} // Pass a function here
        MenuProps={MenuProps}
        sx
        ={{ ...style }}
      >

        {apiData.length === 0 ? (
          <MenuItem disabled>
            <ListItemText primary="No data available" />
          </MenuItem>
        ) : (
          apiData.map(({ id, name }) => (
            <MenuItem key={id} value={name}>
              {multiline && (
                <Checkbox
                  checked={selectedData.indexOf(name) > -1} // Check if the item is selected
                  sx={styles.chkBox}
                />
              )}
              <ListItemText primary={name} />
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

// Default props for better safety and predictability
MultiSelect.defaultProps = {
  apiData: [],
  selectedData: "",
};

export default MultiSelect;



