import React, { useEffect } from "react";
import { Box, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DraftsIcon from '@mui/icons-material/Drafts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
import AmplifySpinner from "../../LoadingSpinner/AmplifySpinner";
import { getAllNotificaitons } from "../../../redux/slices/notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth } from "../../../redux/slices/auth/authSlice";
import { listNotificationAPI, updateNotificationAPI } from "../../../redux/slices/notification/notificationThunk";
import { timeAgo } from "../../../utils/formatTimeSinceNotification";

const NotificationMenu = ({ anchorNotify, handleNotifyClose, openNotify }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userToken } = useSelector(getUserAuth);
  const { isLoading, error, notifications } = useSelector(getAllNotificaitons);


const handleNotifyToBoilerNavigation = (notifyId, boilerId) => {
  let payload = {
    userToken,
    id: notifyId,
    isRead: true, // Make sure this is set correctly
  };
  dispatch(updateNotificationAPI(payload)).then(() => {
    dispatch(listNotificationAPI());
  });
  navigate(`/dashboard/view-boilerplate/${boilerId}`);
};



  const ITEM_HEIGHT = 65;

  return (
     <Menu
      anchorEl={anchorNotify}
      id="account-menu"
      open={openNotify}
      onClose={handleNotifyClose}
      onClick={handleNotifyClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflowY: 'auto',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          maxHeight: ITEM_HEIGHT * 4.5,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            width: "302px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AmplifySpinner />
        </Box>
      ) : (
        <Box>
          {notifications?.length > 0 ? (
            notifications.map((data, index) => (
              <Box key={index}>
                <MenuItem
                  onClick={() => {
                    handleNotifyToBoilerNavigation(data?.id, data?.boilerplate);
                  }}
                  disableRipple
                  sx={{
                    backgroundColor: data.is_read ? 'inherit' : '#f0f8ff',
                    '&:hover': {
                      backgroundColor: data.is_read ? '#f5f5f5' : '#f0f8ff',
                    },
                  }}
                >
                  {data.is_read ? (
                    <DraftsIcon 
                      sx={{ color: '#4caf50', marginRight: 1 }}
                    />
                  ) : (
                    <MarkEmailUnreadIcon sx={{ color: '#4caf50', marginRight: 1 }} />
                  )}
                  <Box
                    sx={{
                      width: "302px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      component="span"
                      variant="body1"
                      sx={{
                        textWrap: "pretty",
                      }}
                    >
                      {data.message}
                    </Typography>
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{
                        color: "#878A99",
                        fontSize: "12px",
                        fontStyle: "italic",
                      }}
                    >
                      {timeAgo(data.created_at)}
                    </Typography>
                  </Box>
                </MenuItem>
                <Divider />
              </Box>
            ))
          ) : (
            <Typography
              component="span"
              variant="body1"
              sx={{
                width: "302px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50px",
                fontSize: "14px",
                fontStyle: "italic",
                color: "#878A99",
              }}
            >
              {error ? error : "No new feedback"}
            </Typography>
          )}
        </Box>
      )}
    </Menu>
  );
};

export default NotificationMenu;
