const styles = {
  techPaper: (theme) => ({
    maxWidth: "934.16px",
    // maxWidth: "1100px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "622.77px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "467.08px",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "350px",
    },
  }),

  techBtnBox: {
    marginBottom: "10px",
  },

  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
  },

  content: {
    flex: 1,
    overflow: "hidden",
  },

  tableContainer: {
    overflowY: "auto",
  },

  responsiveButton: (theme) => ({
    transition: "background-color 0.3s ease, transform 0.2s ease",
    borderRadius: "8px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      transform: "scale(1.05)",
    },
    padding: theme.spacing(1.5, 3),

    // Responsive styles
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Smaller text for small screens
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px", // Medium text for medium screens
      padding: theme.spacing(1.5, 3),
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px", // Larger text for large screens
      padding: theme.spacing(1.5, 4),
    },
  }),
};

export default styles;
