import React from "react";
import { format } from "date-fns";
import BoilerRating from "../boilerRating";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import styles from "./styles";

const Feeds = ({ feed }) => {
  const formattedDate = feed?.created_at
    ? format(new Date(feed.created_at), "MMMM d, yyyy")
    : null;

  return (
    <Box sx={styles.fdPaper}>
      <Stack
        key={feed?.id}
        sx={styles.fdStack} // Use mobile-first styles
      >
        {/* Avatar with background and border */}
        <Avatar
          src={""}
          alt={"user"}
          sx={{
            width: 48,
            height: 48,
            bgcolor: "#3f51b5",
            fontSize: "18px",
            fontWeight: "bold",
            color: "white",
            border: "2px solid #e0e0e0",
          }}
        >
          {feed?.developer_name?.[0]?.toUpperCase() || "U"}
        </Avatar>

        <Box sx={{ flexGrow: 1 }}>
          {/* Developer's name and rating */}
          <Box sx={styles.userFd}>
            <Typography sx={styles.userFdName}>
              {feed?.developer_name}
            </Typography>
            <BoilerRating value={feed?.rating} />
          </Box>

          {/* Feedback text */}
          <Typography sx={styles.userFdText}>
            {feed?.feedback}
          </Typography>

          {/* Date of the comment */}
          {formattedDate && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "#9e9e9e",
                marginTop: "4px",
                fontStyle: "italic",
              }}
            >
              {`Feedback given on ${formattedDate}`}
            </Typography>
          )}
        </Box>
      </Stack>
    </Box>
  );
};

export default Feeds;
