import React, { useEffect } from 'react';
import BrowserTabTitle from '../../../components/BrowserTabTitle';
import { Box, Button, Container, Paper } from '@mui/material';
import PageTitle from '../../../components/Dashboard/includes/pagetitle/PageTitile';
import styles from './styles';
import BoilerTable from '../../../components/Dashboard/table';
import { useDispatch, useSelector } from 'react-redux';
import { categoryListingPaginatedAPI } from '../../../redux/slices/categories/categoriesThunk';
import { getUserAuth, getUserCompanyId } from '../../../redux/slices/auth/authSlice';
import { getAllCategories } from '../../../redux/slices/categories/categoriesSlice';
import { updateIdToCategoryId } from '../../../utils/sliceFilter';
import AddIcon from '@mui/icons-material/Add';
import { ErrorAlert } from '../../../components/Alerts/Alerts';
import { useNavigate } from 'react-router-dom';
import { getURLParams } from '../../../utils/getUrlParams';

const CategoryListing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userToken } = useSelector(getUserAuth);
  const { isLoading, error, categories, previousPageUrl, nextPageUrl, totalCount } = useSelector(getAllCategories);
  const company_id = useSelector(getUserCompanyId);

  useEffect(() => {
    let payload = {};
    payload.userToken = userToken;
    payload.companyId = company_id;
    dispatch(categoryListingPaginatedAPI(payload));
  }, [company_id, dispatch, userToken]);

  const columns = [
    { id: 'category_name', label: 'Category', minWidth: 150.3 },
    { id: 'description', label: 'Description', maxWidth: 186.83 },
    { id: 'is_active', label: 'Active', minWidth: 112.09, align: "center" },
    { id: 'category_id', label: 'Action', minWidth: 112.09, align: "center" },
  ];

  const handleBackendPagination = (link) => {
    if (!link) {
      alert("End of pagination");
      return;
    }
    const params = getURLParams(link);

    let payload = {
      ...params,
      userToken,
      companyId: company_id,
    };
    dispatch(categoryListingPaginatedAPI(payload));
  }

  const handleAddCtgBtn = () => {
    navigate("/dashboard/boilerplate-category/add");
  }

  return (
    <>
      <BrowserTabTitle title={"Boilerplate Category"} />
      {error && <ErrorAlert message={error} />}
      <Container maxWidth="">
        <PageTitle title={"Boilerplate Category"} />
        <Box sx={styles.ctgBtnBox}>
          <Button
            variant="contained"
            onClick={handleAddCtgBtn}
            sx={styles.responsiveButton}
          >
            <AddIcon /> Add Category
          </Button>
        </Box>

        <Paper
          elevation={0}
          square={true}
          component="section"
          sx={styles.ctgPaper}
        >
          <BoilerTable
            columns={columns}
            rows={updateIdToCategoryId(categories)}
            isLoading={isLoading}
            previousPageUrl={previousPageUrl}
            nextPageUrl={nextPageUrl}
            handleBackendPagination={handleBackendPagination}
            totalCount={totalCount}
          />
        </Paper>
      </Container>
    </>
  );
}

export default CategoryListing;