import React, { useState } from "react";
import { Box, Stack, useMediaQuery } from "@mui/material";
import Sidebar from "../components/Dashboard/includes/sidebar";
import Navbar from "../components/Dashboard/includes/navbar/Navbar";

const DashboardLayout = ({ children }) => {
  const [drawOpen, setDrawOpen] = useState(false);

  // Check for screen size to adjust the layout responsively
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const toggleDrawer = (newOpen) => () => {
    setDrawOpen(newOpen);
  };

  return (
    <Stack
      direction="row"
      sx={{
        height: '100vh',
        overflow: 'hidden',
        flexDirection: isSmallScreen ? 'column' : 'row', // Stack column on small screens
      }}
    >
      {/* Sidebar - hide on smaller screens or make collapsible */}
      <Sidebar
        drawOpen={drawOpen}
        setDrawOpen={setDrawOpen}
        toggleDrawer={toggleDrawer}
        sx={{
          display: isSmallScreen ? (drawOpen ? "block" : "none") : "block", // Hide on small screens when not open
        }}
      />

      {/* Right Side (Main Content) */}
      <Box
        flex={11}
        className="dashboard-body"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden", 
        }}
      >
        {/* Navbar */}
        <Navbar
          drawOpen={drawOpen}
          setDrawOpen={setDrawOpen}
          toggleDrawer={toggleDrawer}
        />

        {/* Main Body */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding: { xs: "16px", md: "24px" }, 
          }}
        >
          {children}
        </Box>

        {/* Footer */}
      </Box>
    </Stack>
  );
};

export default DashboardLayout;
