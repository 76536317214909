const styles = {
  abpPaper: (theme) => ({
    maxWidth: "967px",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    padding: "30px 30px",
    [theme.breakpoints.down(1014)]: {
      display: "flex",
      justifyContent: "center",
    },
  }),
  abpStack: (theme) => ({
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),

  responsiveButton: (theme) => ({
    transition: "background-color 0.3s ease, transform 0.2s ease",
    borderRadius: "8px", 
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, 
      transform: "scale(1.05)",
    },
    padding: theme.spacing(1.5, 3), 

    // Responsive styles
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px", // Smaller text for small screens
      padding: theme.spacing(1, 2),
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "14px", // Medium text for medium screens
      padding: theme.spacing(1.5, 3), 
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "16px", // Larger text for large screens
      padding: theme.spacing(1.5, 4),
    },
  }),

  bottomStack: (theme) => ({
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 0,
    },
  }),
  abpdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  abpdfgCAB: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "35px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  }),
  abpdfgDEVs: (theme) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "64px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "15px",
    },
  }),
  abpRmk: (theme) => ({
    display: "flex",
    gap: 1.5,
    flexDirection: "row",
    alignItems: "center",
    pt: 2,
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      alignItems: "flex-start",
      pt: 1,
    },
  }),
  abpBtn: (theme) => ({
    // Any common styles you have for abpBtn
    borderRadius: "8px", // Consistent rounded corners
    fontWeight: "bold", // Bold text for visibility
    color: "#fff", // White text color
    backgroundColor: theme.palette.primary.main, // Primary background color
    "&:hover": {
      backgroundColor: theme.palette.primary.dark, // Darker background on hover
      transform: "scale(1.05)", // Slightly enlarge on hover
    },
    transition: "background-color 0.3s ease, transform 0.2s ease",
  }),

  abpTextField: (theme) => ({
    width: "289px",
  }),

  chipList: (theme) => ({
    display: "flex",
    flexDirection: "row",
    gap: "146px",
    pt: "15px",
    [theme.breakpoints.down(1014)]: {
      flexDirection: "column",
      padding: "10px 0 10px 0",
      gap: 0,
    },
  }),
};

export default styles;