import { zodResolver } from '@hookform/resolvers/zod';
import { Avatar, Box, Button, FormHelperText, Rating, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import styles from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackCreateAPI } from '../../../redux/slices/feedbacks/feedbacksThunk';
import { getUserAuth, userData } from '../../../redux/slices/auth/authSlice';
import { useParams } from 'react-router-dom';
import { getAllFeedbacks } from '../../../redux/slices/feedbacks/feedbacksSlice';
import AmplifySpinner from '../../LoadingSpinner/AmplifySpinner';
import { listNotificationAPI } from '../../../redux/slices/notification/notificationThunk';

const FeedbackForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(userData);
  const { userToken } = useSelector(getUserAuth);
  const { isLoading } = useSelector(getAllFeedbacks);

  const validationSchema = z.object({
    feedback: z.string().trim().min(1, { message: "Feedback is required" }),
    rating: z.number().min(0.5, { message: 'Rating is required' }),
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      feedback: "",
      rating: 0
    }
  });

  const [hover, setHover] = useState(-1);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const payload = {
      ...data,
      id,
      userToken,
      name: user?.name,
      email: user?.email,
    };
    try {
      await dispatch(feedbackCreateAPI(payload));
      await dispatch(listNotificationAPI({ userToken }));
      reset();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        flexDirection="column"
        gap="20px"
        alignItems="flex-start"
        sx={styles.fdStack} // Mobile-first
      >
        <Box>
          <Avatar
            src={user?.picture || ""}
            alt="User"
            sx={{ width: 48, height: 48 }}
          />
        </Box>

        <Box sx={{ flexGrow: 1, width: "100%" }}>
          <TextField
            placeholder="Leave Your Feedback here"
            multiline
            rows={2}
            fullWidth
            id="feedback"
            {...register("feedback")}
            helperText={errors.feedback?.message}
            error={Boolean(errors.feedback)}
            sx={styles.fdTf} // Mobile-first
          />

          <Box sx={styles.fdRating}>
            <Controller
              name="rating"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Rating
                  name="hover-feedback"
                  value={value}
                  precision={0.5}
                  onChange={(event, newValue) => {
                    onChange(newValue !== null ? newValue : 0);
                  }}
                  onChangeActive={(event, newHover) => {
                    setHover(newHover);
                  }}
                  emptyIcon={
                    <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                  }
                  size="large"
                  sx={{
                    '& .MuiRating-iconEmpty': {
                      color: '#e0e0e0', 
                    },
                    '& .MuiRating-iconFilled': {
                      color: '#ff9800', 
                    },
                    '& .MuiRating-iconHover': {
                      color: '#ffb74d', // Change hover color
                    },
                  }}
                />
              )}
            />
            <FormHelperText error>{errors.rating?.message}</FormHelperText>
          </Box>
        </Box>

        <Button
        variant="contained"
        type="submit"
        disabled={isLoading || isSubmitting}
        sx={styles.fdButton} 
      >
        <SendIcon />
      </Button>

      </Stack>
    </form>
  );
};

export default FeedbackForm;
