import { Box, Button, Paper, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getUserAuth, getUserCompanyId } from "../../../redux/slices/auth/authSlice";
import MultiSelect from "../multiSelect";
import CloseIcon from '@mui/icons-material/Close'
import { MuiFileInput } from "mui-file-input";
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { techStackCreateAPI, techStackUpdateAPI } from "../../../redux/slices/techStacks/techStacksThunk";
import { useNavigate } from "react-router-dom";

const TechstackForm = ({ techDetail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userToken } = useSelector(getUserAuth);
  const company_id = useSelector(getUserCompanyId);
  const [isActive, setIsActive] = useState();

  const [activeStatus] = useState([
    { id: 1, name: "Not Active" },
    { id: 2, name: "Active" }
  ]);

  const validationSchema = z.object({
    name: z.string().trim().min(1, { message: "Name is requried" }),
    description: z.any(),
    isActive: z.any(),
    icon: z.any(),
  });

  const {
    register,
    control,
    setError,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: "",
      description: "",
    }
  });

  useEffect(() => {
    if (techDetail) {
      reset({
        name: techDetail.tech_name || "",
        description: techDetail.description || "",
      });
      setIsActive(techDetail?.is_active ? "Active" : "Not Active")
    }
  }, [techDetail, dispatch, reset]);


  const onSubmit = (data, e) => {
    e.preventDefault();

    if ((!techDetail) && (
      data.icon === undefined ||
      data.icon === null ||
      data.icon === ""
    )) {
      setError("icon", {
        message: "Icon file is required",
      })
      return
    }

    const payload = {
      ...data,
      id: techDetail?.id,
      userToken,
      companyId:company_id,
      navigate,
    };

    techDetail ?
      dispatch(techStackUpdateAPI(payload)) :
      dispatch(techStackCreateAPI(payload));
  };

  return (
    <>
      <Paper
        elevation={0}
        square={true}
        component="section"
        sx={styles.techPaper}
      >
        <form
          autoComplete={"off"}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Techstack Name */}
          <Box sx={styles.techdf}>
            <Typography component="span" variant="body1">
              Name
            </Typography>
            <TextField
              placeholder="Add techstack name here"
              id="name"
              type="text"
              {...register("name")}
              helperText={errors.name?.message}
              error={Boolean(errors.name)}
              sx={styles.techTextField}
            />
          </Box>

          {/* Techstack Description */}
          <Box sx={styles.techdf} pt={2}>
            <Typography component="span" variant="body1">
              Description
            </Typography>
            <TextField
              placeholder="Add techstack description here"
              multiline
              rows={6}
              id="description"
              {...register("description")}
              helperText={errors.description?.message}
              error={Boolean(errors.description)}
              sx={styles.techTextField}
            />
          </Box>

          {/* Techstack Description */}
          {techDetail && (
            <Box sx={styles.techdf} pt={2}>
              <Typography component="span" variant="body1">
                Tech Icon
              </Typography>
              <img src={techDetail?.icon_file} alt="icon" width={50} />
            </Box>
          )}

          {/* Upload Icon */}
          <Box sx={styles.techdf} pt={2}>
            <Typography component="span" variant="body1">
              Upload Tech Icon
            </Typography>
            <Controller
              name="icon"
              control={control}
              render={({ field }) => (
                <MuiFileInput
                  {...field}
                  helperText={errors?.icon?.message}
                  error={Boolean(errors?.icon)}
                  sx={styles.techTextField}
                  clearIconButtonProps={{
                    title: "Remove",
                    children: <CloseIcon fontSize="small" />
                  }}
                  InputProps={{
                    startAdornment: <FolderZipIcon fontSize="medium" />,
                    inputProps: {
                      accept: 'image/jpeg,image/png,image/webp',
                    },
                  }}
                />
              )}
            />
          </Box>

          {/* Select Status */}
          {techDetail &&
            <Box sx={styles.techdf} pt={2}>
              <Typography component="span" variant="body1">
                Active Status
              </Typography>
              <MultiSelect
                placeholder={"Active status"}
                id={"isActive"}
                selectedData={isActive}
                setSelectedData={setIsActive}
                apiData={activeStatus}
                register={register("isActive")}
                error={errors.isActive}
                multiline={false}
                style={{ width: "380px" }}
              />
            </Box>
          }

          {/* Submit Button */}
          <Box pt={3}>
            <Button
              variant="contained"
              type={"submit"}
              sx={styles.techBtn}
            >
              {!techDetail ?
                "Add Techstack" :
                "Update Techstack"}
            </Button>
          </Box>
        </form>
      </Paper >
    </>
  )
}

export default TechstackForm;
