const styles = {
  sbBox: (theme) => ({
    width: "249px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "249px",
      backgroundColor: "#F6F8FA",
      borderRight: "1px solid #E9EBEC",
      transition: "all 0.3s ease",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    [theme.breakpoints.down(1268)]: {
      display: "none",
    },
  }),
  sbBoxBd: (theme) => ({
    width: "249px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      width: "249px",
      backgroundColor: "#F6F8FA",
      borderRight: "1px solid #E9EBEC",
      transition: "all 0.3s ease",
    },
  }),
  sbLogo: (theme) => ({
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingBottom: "16px",
    backgroundColor: "#fff",
    borderBottom: "1px solid #E9EBEC",
  }),
  sbTabs: (theme) => ({
    paddingTop: "20px",
    paddingLeft: "0 !important",
  }),
  sbTabsList: (theme) => ({
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "12px",
    paddingBottom: "12px",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      transition: "background-color 0.3s ease",
    },
  }),
  sbTabsListIcon: (theme) => ({
    minWidth: "33px !important",
    color: theme.palette.secondary.main,
  }),
  sbTabsSubIcon: (theme) => ({
    width: "33px !important",
    color: theme.palette.secondary.light,
  }),
  sbMenuText: (theme) => ({
    "& .MuiListItemText-primary": {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "22.5px",
      color: theme.palette.text.primary,
      textTransform: "capitalize",
    },
  }),
  sbSubMenuIcon: (theme) => ({
    minWidth: "20px !important",
    color: theme.palette.secondary.main,
  }),
  sbSubMenuText: (theme) => ({
    "& .MuiListItemText-primary": {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#7C7F90",
    },
    "&:hover": {
      color: theme.palette.primary.main,
    },
  }),
  sbSelectSubMenuText: (theme) => ({
    "& .MuiListItemText-primary": {
      fontWeight: 500,
      fontSize: "15px",
      lineHeight: "21px",
      color: "#ffffff",
    },
    // backgroundColor: theme.palette.primary.main,
    borderRadius: "4px",
    transition: "all 0.3s ease",
  }),
};

export default styles;
