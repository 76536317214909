import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";

// Thunks

// create feedback API
export const feedbackCreateAPI = createAsyncThunk(
  "feedbacks/feedbackCreateAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, id, name, email, feedback, rating } = payload;
    try {
      const body = {
        developer_name: name,
        developer_email: email,
        feedback,
        rating,
        boilerplate: id,
      };
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.post(`/api/feedback/`, body, { headers });

      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// get all feedbacks according to specific boilerplate id
export const getFeedbacksAPI = createAsyncThunk(
  "feedbacks/getFeedbacksAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, id } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(`/api/feedback/${id}`, { headers });
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk handlers

export const handlers = {
  feedbackCreateAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      const newFeedback = action?.payload;
      state.isLoading = false;
      state.feedbacks = [...state.feedbacks, newFeedback];
      state.isSuccess = true;
      state.message = "Feedback done";
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  getFeedbacksAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.feedbacks = data;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },
};
