import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import styles from "./styles";

const DevName = ({ name }) => {
  return (
    <Box sx={styles.userProfile}>
      {/* <Avatar alt="User" src="" sx={styles.userAvatar} /> */}
      <Box ml={1}>
        <Typography component="span" sx={styles.username}>
          {name}
        </Typography>
      </Box>
    </Box>
  )
}

export default DevName;