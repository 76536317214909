const styles = {
  tableHeadCell: (theme) => ({
    backgroundColor: "#F3F6F9",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#5b5b5b",
    fontWeight: 600,
    padding: "16px 8px",
    borderBottom: "1px solid #E0E3E7",
    letterSpacing: "0.05em",
    textTransform: "uppercase",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",

    [`@media (max-width: 600px)`]: {
      fontSize: "10px",
      padding: "8px",
      whiteSpace: "nowrap",
    },
  }),

  tableContainer: {
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    borderRadius: "8px",
    overflowX: "auto",
    overflowY: "hidden",
    backgroundColor: "#ffffff",
    width: "100%",
    maxWidth: "100%",
    [`@media (max-width: 600px)`]: {
      marginBottom: "1rem",
    },
  },
  tablePagination: {
    paddingBottom: "10px",
    borderTop: "1px solid #ddd",
    ".MuiTablePagination-displayedRows": {
      fontSize: "16px",
      [`@media (max-width: 600px)`]: {
        fontSize: "12px",
      },
    },
    ".MuiTablePagination-actions": {
      [`@media (max-width: 600px)`]: {
        fontSize: "12px",
        margin: "0 4px",
      },
    },
    ".MuiTablePagination-toolbar": {
      [`@media (max-width: 600px)`]: {
        padding: "0",
        minHeight: "40px",
      },
    },
  },

  tableRowActive: (theme) => ({
    backgroundColor: "#FFFFFF", // White for active rows
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#f1f1f1", // Slightly darker on hover
    },
  }),

  tableRowInactive: (theme) => ({
    backgroundColor: "#ffe6e6", // Light pink for inactive rows
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#ffcccc", // Slightly darker on hover
    },
  }),
  tableBodyCell: (theme) => ({
    backgroundColor: "#FFFFFF",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#333333",
    fontWeight: 400,
    padding: "6px 8px",
    borderBottom: "1px solid #E0E3E7",
    [`@media (max-width: 600px)`]: {
      fontSize: "10px",
      padding: "8px",
      whiteSpace: "nowrap",
      backgroundColor: "#F7F7F7",
    },
  }),
  tableEmptyText: (theme) => ({
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "italic",
    height: "25vh",
    color: "#878A99",
    textAlign: "center",
    padding: "20px",
  }),
  tableNA: (theme) => ({
    fontSize: "14px",
    lineHeight: "21px",
    color: "#888",
    fontStyle: "italic",
  }),

  userProfile: (theme) => ({
    display: "flex",
    alignItems: "center",
    mr: 1,
  }),
  userAvatar: (theme) => ({
    width: 32,
    height: 32,
    borderRadius: "50%",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }),
  username: (theme) => ({
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#212529",
    marginLeft: "8px",
  }),
};

export default styles;
