const styles = {
  blPaper: (theme) => ({
    maxWidth: "100%",
    border: "1px solid #E9EBEC",
    borderRadius: "4px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.down(500)]: {
      maxWidth: "100%",
    },
  }),

  nextPrevBtn: (theme) => ({
    backgroundColor: "#F3F6F9",
  }),
};
export default styles;