import React, { useEffect } from "react";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, FormControlLabel, FormHelperText, IconButton, Radio, RadioGroup, Tooltip, Typography } from "@mui/material";

import styles from "./styles";
import FormModal from "../../../../../components/Dashboard/formModal/FormModal";
import { closeDRModal, isDRModalOpen } from "../../../../../redux/slices/modal/ModalSlice";
import { getUserAuth } from "../../../../../redux/slices/auth/authSlice";
import { getDownloadReasons } from "../../../../../redux/slices/downloadReason/downloadReasonSlice";
import { useParams } from "react-router-dom";
import { postdownloadReasonAPI } from "../../../../../redux/slices/downloadReason/downloadReasonThunk";
import { getBoilerplateDetail } from "../../../../../redux/slices/boilerplate/boilerplateSlice";
import AmplifySpinner from "../../../../../components/LoadingSpinner/AmplifySpinner";
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const DownloadReasonModal = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const isOpen = useSelector(isDRModalOpen);
  const { userToken } = useSelector(getUserAuth);
  const boilerDetail = useSelector(getBoilerplateDetail);
  const { isLoading, isSuccess, error, downloadReason } = useSelector(getDownloadReasons);

  const validationSchema = z.object({
    radioGroup: z.string().min(1, 'Please select an option'),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {}
  });


  const handleDownloadZip = (fileUrl) => {
    if (!fileUrl) {
      alert("Zip Url is missing")
      return
    }
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = fileUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    reset();
    dispatch(closeDRModal());
  };



  const onSubmit = (data, e) => {
    e.preventDefault();
    let payload = {};
    payload.userToken = userToken;
    payload.id = id;
    payload.downloadReason = data?.radioGroup;
    dispatch(postdownloadReasonAPI(payload));
  };


  const handleClose = () => {
    reset();
    dispatch(closeDRModal());
  };


  return (
    <FormModal
      isOpen={isOpen}
      handleClose={handleClose}
      heading="Download Reason"
      styles={styles}
      isIcon={false}
    >
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box pt={1}>
          <Box>
            <Controller
              name="radioGroup"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup {...field}>
                  {downloadReason?.map((item, index) => {
                    const value = Object.keys(item)[0];
                    const label = item[value];
                    return (
                      <FormControlLabel
                        key={index}
                        value={value}
                        control={<Radio />}
                        label={label}
                      />
                    );
                  })}
                </RadioGroup>
              )}
            />
            <FormHelperText error>
              {error ?
                error :
                errors.radioGroup?.message}
            </FormHelperText>
          </Box>

          <Box pt={1} sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "20px" }}>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading || isSuccess}
                sx={styles.modalBtn}
              >
                {isLoading ? (
                  <AmplifySpinner />
                ) : (
                  isSuccess ? "Submitted" : "Submit"
                )}
              </Button>
            </Box>

            {/* File Download Button  */}
            {isSuccess && (
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <Typography component="span" variant="body1">
                  Reason recorded successfully. Click the download icon to start downloading.
                </Typography>
                <Tooltip title="Download Zip code file" placement="right">
                  <IconButton
                    aria-label="edit"
                    size="small"
                    onClick={() => handleDownloadZip(boilerDetail?.code_zip_file)}
                  >
                    <FileDownloadIcon fontSize={"large"} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>

        </Box>
      </form>
    </FormModal>
  );
};

export default DownloadReasonModal;
