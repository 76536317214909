import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import styles from './styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from 'react-router-dom';

const PageTitle = ({ title, goBackPath }) => {
  const navigate = useNavigate()
  const handleBack = (path) => {
    navigate(path)
  }

  return (
    <>
      <Box sx={styles.ptBox}>
        <Typography variant="h2">
          {goBackPath && (
            <IconButton
              aria-label="back-icon"
              size="small"
              onClick={() => handleBack(goBackPath)}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          )}
          {title}
        </Typography>
      </Box>
    </>
  )
}

export default PageTitle;