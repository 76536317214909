const styles = {
  afStack: (theme) => ({
    gap: "20px",
    flexWrap:"wrap",
    background: "#f9fafb",
    borderRadius: "10px",
    padding: "20px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "15px", // Adjust the gap for medium screens
      padding: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
    },
  }),
  ctgdf: (theme) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  }),
  closeBtn: (theme) => ({
    display: "flex",
    marginTop: "30px",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  }),
  ctgTextField: (theme) => ({
    width: "100%",
    maxWidth: "290px",
    transition: "all 0.3s ease-in-out",
    "& .MuiOutlinedInput-root": {
      background: "#f0f0f0",
      borderRadius: "8px",
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.05)",
      "&:hover": {
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }),
  ctgMS: (theme) => ({
    width: "100%",
    maxWidth: "380px",
    background: "#f0f0f0",
    borderRadius: "8px", // Rounded corners
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "290px",
    },
  }),
  ctgBtn: (theme) => ({
    padding: "10px 40px",
    marginTop: "24px",
    borderRadius: "8px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white, // White text
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
      transform: "scale(1.05)", // Scale effect on hover
      padding: "10px 40px", // Reduce padding on medium screens
      marginTop: "20px", // Adjust top margin to fit within the container
      width: "100%", // Make the button take full width on medium screens
      boxSizing: "border-box",
    },
    [theme.breakpoints.down("md")]: {
      padding: "8px 20px", // Further reduce padding for medium screens
      width: "100%", // Make button take full width
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 40px",
    },
  }),
  titleText: (theme) => ({
    fontWeight: "600",
    color: "#333",
    marginBottom: "8px",
    transition: "all 0.3s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  }),
};

export default styles;
