import { createSlice } from "@reduxjs/toolkit";
import {
  handlers,
  techStackCreateAPI,
  techStackDetailAPI,
  techStackListingAPI,
  techStackUpdateAPI,
  techStackListingPaginatedAPI,
  techStackFullListAPI,
} from "./techStacksThunk";

const initialState = {
  isLoading: false,
  error: null,
  message: null,
  isSuccess: false,
  fullTechStacks: [],
  techStacks: [],
  techStackDetail: [],
  selectedTech: null,
  selectedCategory: null,
  previousPageUrl: null,
  nextPageUrl: null,
  totalCount: 0,
};

export const techStack = createSlice({
  name: "techStacks",
  initialState,
  reducers: {
    selectedTechstack: (state, action) => {
      state.selectedTech = action?.payload;
      state.selectedCategory = null; // Reset selected category when a tech stack is selected
    },
    resetSelectedCategory: (state) => {
      state.selectedCategory = null; // Action to reset the selected category
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      techStackListingAPI.pending,
      handlers.techStackListingAPI.pending
    );
    builder.addCase(
      techStackListingAPI.fulfilled,
      handlers.techStackListingAPI.success
    );
    builder.addCase(
      techStackListingAPI.rejected,
      handlers.techStackListingAPI.rejected
    );

    builder.addCase(techStackFullListAPI.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(techStackFullListAPI.fulfilled, (state, action) => {
      state.fullTechStacks = action.payload.data || [];
      state.isLoading = false;
    });
    builder.addCase(techStackFullListAPI.rejected, (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    });

    builder.addCase(
      techStackListingPaginatedAPI.pending,
      handlers.techStackListingPaginatedAPI.pending
    );
    builder.addCase(
      techStackListingPaginatedAPI.fulfilled,
      handlers.techStackListingPaginatedAPI.success
    );
    builder.addCase(
      techStackListingPaginatedAPI.rejected,
      handlers.techStackListingPaginatedAPI.rejected
    );

    builder.addCase(
      techStackCreateAPI.pending,
      handlers.techStackCreateAPI.pending
    );
    builder.addCase(
      techStackCreateAPI.fulfilled,
      handlers.techStackCreateAPI.success
    );
    builder.addCase(
      techStackCreateAPI.rejected,
      handlers.techStackCreateAPI.rejected
    );

    builder.addCase(
      techStackDetailAPI.pending,
      handlers.techStackDetailAPI.pending
    );
    builder.addCase(
      techStackDetailAPI.fulfilled,
      handlers.techStackDetailAPI.success
    );
    builder.addCase(
      techStackDetailAPI.rejected,
      handlers.techStackDetailAPI.rejected
    );

    builder.addCase(
      techStackUpdateAPI.pending,
      handlers.techStackUpdateAPI.pending
    );
    builder.addCase(
      techStackUpdateAPI.fulfilled,
      handlers.techStackUpdateAPI.success
    );
    builder.addCase(
      techStackUpdateAPI.rejected,
      handlers.techStackUpdateAPI.rejected
    );
  },
});

export const { selectedTechstack, resetSelectedCategory } = techStack.actions;
export const getAllTechStacks = (state) => state.techStacks;
export const getSelectedTechStack = (state) => state.techStacks.selectedTech;

export default techStack.reducer;
