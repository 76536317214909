import React from "react";

import { Box, Container } from "@mui/material";

import styles from "./styles";
import GoogleLogin from "./GoogleLogin";
import colorLogo from "../../assets/login/logo_colored.svg";
import img2 from "../../assets/login/bottom_right_corner.svg";

const MiddleSide = () => {
  return (
    <Box flex={4} className="d-flex">
      <Container maxWidth={"md"} sx={styles.midContainer}>
        <Box sx={styles.midBox}>
          <Box></Box>
          <Box>
            <img src={colorLogo} alt="purelogics" draggable="false" />
            <Box mt={6}>
              <GoogleLogin text={"Login with Company Account"} />
            </Box>
          </Box>
          <Box mb={4} mr={4}>
            <img src={img2} alt="downcorner" draggable="false" />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default MiddleSide;
