import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';


const CtgStatus = ({ value }) => {
  return (
    <>
      {value ? (
        <CheckIcon sx={{ fontSize: "18px", color: "green" }} />) : (
        <ClearSharpIcon sx={{ fontSize: "18px", color: "red" }} />
      )}
    </>
  )
}

export default CtgStatus;