import React from 'react';
import { Chip } from '@mui/material';

const BoilerChip = ({ index, item, deleteIcon, handleDelete, styles }) => {


  return (
    <Chip
      key={index}
      label={item}
      deleteIcon={deleteIcon && deleteIcon}
      onDelete={() => handleDelete(index)}
      sx={styles}
    />
  )
}

export default BoilerChip;