import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/axiosInstance";

// boilerplate download reason api to get all reasons, which will be used as a payload for
export const getDownloadReasonAPI = createAsyncThunk(
  "boilerplate/downloadReasonAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken } = payload;
    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.get(`/api/download-reason`, { headers });
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// create category api for creating categories
export const postdownloadReasonAPI = createAsyncThunk(
  "boilerplate/postDownloadReasonAPI",
  async (payload, { rejectWithValue }) => {
    const { userToken, id, downloadReason } = payload;

    const body = {
      reason: downloadReason,
    };

    try {
      const headers = { Authorization: `Bearer ${userToken}` };
      const { data } = await api.post(`/api/download-boilerplate/${id}`, body, {
        headers,
      });
      return data;
    } catch (error) {
      if (error?.response?.data?.error) {
        return rejectWithValue(error.response.data.error);
      }

      if (error?.message) {
        return rejectWithValue(error.message);
      }

      if (error) {
        return rejectWithValue("Something went wrong");
      }
    }
  }
);

// Thunk handlers

export const handlers = {
  // get all download reasons
  getDownloadReasonAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.message = null;
      state.isSuccess = false;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      const { data } = action?.payload;
      state.isLoading = false;
      state.downloadReason = data;
      state.error = null;
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },

  // post the download reasons
  postdownloadReasonAPI: {
    pending: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.message = null;
      state.error = null;
      return state;
    },
    success: (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.message = "Reason submitted successfully";
      return state;
    },
    rejected: (state, action) => {
      state.isLoading = false;
      state.error = action?.payload;
      return state;
    },
  },
};
