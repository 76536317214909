import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ThemeProvider } from '@emotion/react';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import theme from './theme';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));
let persistor = persistStore(store);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <ToastContainer
              position='top-center'
              theme='light'
              autoClose={2500}
              pauseOnHover
              closeOnClick
              pauseOnFocusLoss={false}
            />
            <App />
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    </ThemeProvider>
  </BrowserRouter>
);
