import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import authReducer from "../redux/slices/auth/authSlice";
import modalReducer from "../redux/slices/modal/ModalSlice";
import categoriesReducer from "../redux/slices/categories/categoriesSlice";
import techStackReducer from "../redux/slices/techStacks/techStacksSlice";
import codeAuditStatusReducer from "../redux/slices/codeAuditStatus/codeAuditStatusSlice";
import boilerplateReducer from "../redux/slices/boilerplate/boilerplateSlice";
import auditorReducer from "../redux/slices/codeAuditors/codeAuditorsSlice";
import developerReducer from "../redux/slices/developers/devlopersSlice";
import feedbackReducer from "../redux/slices/feedbacks/feedbacksSlice";
import notificationReducer from "../redux/slices/notification/notificationSlice";
import downloadReasonReducer from "../redux/slices/downloadReason/downloadReasonSlice";

// Persist configuration for the auth slice
const authPersistConfig = {
  key: "auth",
  version: 1,
  storage,
  blacklist: ["error", "isLoading"],
};

// Persist configuration for the auth slice
// const categoriesPersistConfig = {
//   key: 'categories',
//   version: 1,
//   storage,
//   blacklist: ['error', 'isLoading'],
// }

// Combine reducers and apply persistReducer to the auth slice
const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  categories: categoriesReducer,
  techStacks: techStackReducer,
  codeAuditStatus: codeAuditStatusReducer,
  boilerplates: boilerplateReducer,
  feedbacks: feedbackReducer,
  auditors: auditorReducer,
  developers: developerReducer,
  notifications: notificationReducer,
  downloadReason: downloadReasonReducer,
  modal: modalReducer,
});

// Apply persistReducer to the root reducer
const persistedReducer = rootReducer;

// Configure the store with the persisted reducer
export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
