import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getUserAuth } from "../redux/slices/auth/authSlice";
import { checkIsAdmin } from "../utils/checkIsAdmin";
import { useEffect } from "react";

const AdminProtectedRoutes = () => {
  const navigate = useNavigate();
  const { isAdmin, userProfile } = useSelector(getUserAuth);
  const checkAdmin = checkIsAdmin(isAdmin, userProfile?.userType);

  useEffect(() => {
    if (!checkAdmin) {
      alert("Access denied");
      navigate("/dashboard/boilerplate-listing/MERN");
    }
  }, [checkAdmin, navigate]);

  if (!checkAdmin) {
    return null;
  }

  return <Outlet />;
};

export default AdminProtectedRoutes;
