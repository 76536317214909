import React from "react";

import { Box, Stack } from "@mui/material";

import BrowserTabTitle from "../../components/BrowserTabTitle";
import LeftSide from "../../components/Login/LeftSide";
import RightSide from "../../components/Login/RightSide";
import MiddleSide from "../../components/Login/MiddleSide";

const Login = () => {
  return (
    <>
      <BrowserTabTitle title={"Login"} />

      <Box>
        <Stack direction="row" className="vh100">
          <LeftSide />
          <MiddleSide />
          <RightSide />
        </Stack>
      </Box>
    </>
  );
};

export default Login;
