import { isExpired } from "react-jwt";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { getUserAuth } from "../redux/slices/auth/authSlice";

const ProtectedRoutes = ({ children }) => {

  const { userToken } = useSelector(getUserAuth);

  if (userToken) {
    const expired = isExpired(userToken);
    if (!expired) {
      return <Outlet />;
    }
    else {
      localStorage.clear();
      return <Navigate to="/login" replace />;
    }
  } 
  else {
    return <Navigate to="/login" replace />;
  }

};

export default ProtectedRoutes;